<template>
  <div class="container-fluid">
    <div class="register">注册</div>
    <div class="container-body" style="border-top-right-radius: 20px;border-top-left-radius: 20px">
      <legend class="legend-style">登录信息</legend>
      <!-- 用户名 -->
      <div class="form-group">
        <label for="username" class="control-label">
          用户名 <span class="require">*</span>
        </label>
        <div class="input">
          <input v-model="signupForm.username"
                 type="text"
                 class="form-control"
                 id="username"
                 :placeholder="username"
                 :class="{ error : checkField.usernameinfo }"
                 @blur="checkUserName"
                 @input="!isRegBtnDisable && checkUserName()">
          <div>
            <div class="status-point" style="background-color: #FFB22D"/>
            <div>用户名由6-20个英文字母或数字组成</div>
          </div>
          <div>
            <div class="status-point" style="background-color: #00CF0F"/>
            <div>用户名一经注册无法修改</div>
          </div>
          <div style="color: red">{{ checkField.usernameinfo }}</div>
        </div>
      </div>
      <!-- 密码 -->
      <div class="form-group">
        <label for="password" class="control-label">密码 <span class="require">*</span></label>
        <div class="input">
          <input v-model="signupForm.password"
                 type="password"
                 id="password"
                 :placeholder="password"
                 class="form-control"
                 :class="{ error : checkField.passwordinfo }"
                 @blur="checkPassword"
                 @input="!isRegBtnDisable && checkPassword()">
          <div>
            <div class="status-point" style="background-color: #FFB22D"/>
            <div>密码由6-20个英文字母或数字组成</div>
          </div>
          <div style="color: red">{{ checkField.passwordinfo }}</div>
        </div>
      </div>
      <!-- 确认密码 -->
      <div class="form-group">
        <label for="repassword" class="control-label">确认密码 <span class="require">*</span>
        </label>
        <div class="input">
          <input v-model="signupForm.repassword"
                 type="password"
                 id="repassword"
                 :placeholder="reEnterPassword"
                 :class="{ error : checkField.repasswordinfo }"
                 class="form-control"
                 @blur="chechRepassword"
                 @input="!isRegBtnDisable && chechRepassword()">
          <div style="color: red">{{ checkField.repasswordinfo }}</div>
        </div>
      </div>
    </div>
    <div class="container-body">
      <legend class="legend-style">公司信息</legend>
      <!-- 公司名称 -->
      <div class="form-group">
        <label for="companyname" class="control-label">公司名称 <span class="require">*</span></label>
        <div class="input">
          <input v-model="signupForm.companyname"
                 type="text"
                 id="companyname"
                 :placeholder="companyName"
                 class="form-control"
                 :class="{ error : checkField.companynameinfo }"
                 @blur="checkCompanyName"
                 @input="!isRegBtnDisable && checkCompanyName()">
          <div style="color: red">{{ checkField.companynameinfo }}</div>
        </div>
      </div>
      <!-- 公司英文名称 -->
      <div class="form-group">
        <label for="companylocalname"
               style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-end; width: 350px">
          <div>公司英文名称 </div>
        </label>
        <div>
          <input v-model="signupForm.companylocalname"
                 type="text"
                 id="companylocalname"
                 :placeholder="companyNameEn"
                 class="form-control">
        </div>
      </div>
      <!-- 网址 -->
      <div class="form-group">
        <label for="website" class=" control-label">网址 </label>
        <div class="">
          <input v-model="signupForm.website"
                 type="text"
                 :placeholder="website"
                 class="form-control"
                 id="website">
        </div>
      </div>
      <div class="form-group">
        <!-- 涉及产品 -->
        <label class="control-label">涉及产品 </label>
        <div style="width: 488px;height:35px;margin-left: 20px;background-color: #EDEDED;border-radius:5px;display: flex;align-items: center;font-size: 16px">
          <input style="margin-left: 20px" v-model="producttype" value="_beef" class="checkbox-style" type="checkbox">牛肉
          <input style="margin-left: 20px" v-model="producttype" value="_pork" class="checkbox-style" type="checkbox">猪肉
        </div>
      </div>
      <!-- 公司类型 -->
      <div class="form-group">
        <label style="width: 350px;display: flex;flex-direction: column;justify-content: center;align-items: flex-end">
          <div>公司类型 <span class="require">*</span>
          <div style="text-align: center;font-size: 14px">(可多选)</div>
          </div>
        </label>
        <div class="checkbox">
          <div style="width: 100%; height: auto; border: 1px solid #707070; padding: 5px 0; border-radius: 8px"
               :class="{ error : checkField.companytypeinfo }">
            <!-- beef -->
            <div>
              <span style="width: 250px"><input v-model="companytype" value="_cattlefarm" type="checkbox" class="checkbox-style" @change="checkCompanyType">肉牛养殖企业</span>
              <input v-model="companytype" value="_pigfarm" type="checkbox" class="checkbox-style" @change="checkCompanyType">生猪养殖企业
            </div>
            <div>
              <span style="width: 250px"><input v-model="companytype" value="_cattleslaughterhouse" type="checkbox" class="checkbox-style" @change="checkCompanyType">肉牛屠宰加工企业</span>
              <input v-model="companytype" value="_pigslaughterhouse" type="checkbox" class="checkbox-style" @change="checkCompanyType">生猪屠宰加工企业
            </div>
            <div>
              <span style="width: 250px"><input v-model="companytype" value="_beefexporter" type="checkbox" class="checkbox-style" @change="checkCompanyType">对华牛肉出口商</span>
              <input v-model="companytype" value="_porkexporter" type="checkbox" class="checkbox-style" @change="checkCompanyType">对华猪肉出口商
            </div>
            <div>
              <span style="width: 250px"><input v-model="companytype" value="_beefimporter" type="checkbox" class="checkbox-style" @change="checkCompanyType">中国牛肉进口商</span>
              <input v-model="companytype" value="_porkimporter" type="checkbox" class="checkbox-style" @change="checkCompanyType">中国猪肉进口商
            </div>
            <div>
              <span style="width: 250px"><input v-model="companytype" value="_beefdistributer" type="checkbox" class="checkbox-style" @change="checkCompanyType">中国牛肉批发商/贸易商</span>
              <input v-model="companytype" value="_porkdistributer" type="checkbox" class="checkbox-style" @change="checkCompanyType">中国猪肉批发商/贸易商
            </div>
            <div>
              <span style="width: 250px"><input v-model="companytype" value="_beefproductproducer" type="checkbox" class="checkbox-style" @change="checkCompanyType">中国牛肉制品生产加工企业</span>
              <input v-model="companytype" value="_porkproductproducer" type="checkbox" class="checkbox-style" @change="checkCompanyType">中国猪肉制品生产加工企业
            </div>
            <div>
              <span style="width: 250px"><input v-model="companytype" value="_restaurant" type="checkbox" class="checkbox-style" @change="checkCompanyType">中国餐饮企业/超市</span>
              <input v-model="companytype" value="_other" type="checkbox" class="checkbox-style" @change="checkCompanyType">其他
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-body" style="border-bottom-right-radius: 20px;border-bottom-left-radius: 20px">
      <legend class="legend-style">个人信息</legend>
      <!-- 国家 -->
      <div class="form-group">
        <label for="country" class="control-label">国家 <span class="require">*</span></label>
        <div style="width: 489px;display: flex;justify-content: flex-start">
          <div>
            <select v-model="signupForm.country"
                    class="form-control"
                    :class="{ error : checkField.countryinfo }"
                    id="country"
                    @change="checkCountry">
              <option v-for="country in countrydata" :key="country" class="form-control" :value="country">
                {{ $t(`message["${country}"]`) }}
              </option>
            </select>
          </div>
          <!--省/直辖市-->
          <div style="display: flex;flex-direction: column">
            <div style="display: flex">
              <label for="province" class="control-label" style="width:150px!important;">省/直辖市 <span
                class="require">*</span></label>
              <select v-model="signupForm.province"
                      :disabled="disableProvince"
                      class="form-control"
                      :class="{ error : checkField.provinceinfo }"
                      id="province"
                      @change="checkProvince">
                <option v-for="province in provincelist" :key="province" :value="province">
                  {{ $t(`message["${province}"]`) }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- 联系人 -->
      <div class="form-group">
        <label for="contactpersonname" class="control-label">联系人 <span class="require">*</span></label>
        <div style="width: 489px;display: flex;justify-content: flex-start">
          <div class="input">
            <input v-model="signupForm.contactpersonname"
                   style="width: 160px!important;"
                   type="text"
                   id="contactpersonname"
                   class="form-control"
                   :class="{ error : checkField.contactpersonnameinfo }"
                   @blur="checkContact"
                   @input="!isRegBtnDisable && checkContact()">
            <div style="color: red">{{ checkField.contactpersonnameinfo }}</div>
          </div>
          <!--性别 -->
          <div style="display: flex;flex-direction: column">
            <div style="display: flex">
              <label for="contactpersonsex" class="control-label" style="width:150px!important;">性别
                <span
                  class="require">*</span></label>
              <select v-model="signupForm.contactpersonsex"
                      :disabled="disableProvince" class="form-control"
                      :class="{ error : checkField.contactpersonsexinfo }"
                      id="contactpersonsex"
                      @change="checkSex">
                <option value="_mr">Mr</option>
                <option value="_ms">Ms</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <!-- 英文名或拼音  -->
      <div class="form-group">
        <label for="contactpersonenglishname"
               style="display: flex;flex-direction: column;justify-content: flex-start;align-items: flex-end; width: 350px">
          <div>英文名或拼音 </div>
        </label>
        <div class="">
          <input v-model="signupForm.contactpersonenglishname"
                 type="text"
                 class="form-control"
                 id="contactpersonenglishname">
        </div>
      </div>
      <!-- 手机 -->
      <div class="form-group">
        <label for="companyphone" class="control-label">手机 <span class="require">*</span></label>
        <div class="input">
          <input v-model="signupForm.companyphone"
                 type="text"
                 id="companyphone"
                 class="form-control"
                 :class="{ error : checkField.companyphoneinfo }"
                 @blur="checkPhone"
                 @input="!isRegBtnDisable && checkPhone()">
          <div style="color: red">{{ checkField.companyphoneinfo }}</div>
        </div>
      </div>

      <!-- 验证码 -->
      <div class="form-group">
        <label for="validcode" class="control-label">验证码 <span class="require">*</span></label>
        <div  style="width: 489px;display: flex;justify-content: space-between">
          <div>
            <input style="width: 244px" v-model="signupForm.validcode" type="text" class="form-control" id="validcode" @blur="checkCode" @input="!isRegBtnDisable && checkCode()">
            <div style="color: red;margin-left: 20px">{{ checkField.validcodeinfo }}</div>
          </div>
          <button style="height: 35px;width: 120px;display: flex;justify-content: center;font-size: 14px" :disabled="isActive" class=" control-label btn btn-primary" @click.prevent="fnCountDown">{{ countcontent }}</button>
        </div>
      </div>
      <!-- 电话 -->
      <div class="form-group">
        <label for="cellphone" class="control-label">电话 </label>
        <div class="input">
          <input v-model="signupForm.cellphone"
                 type="text"
                 id="cellphone"
                 class="form-control">
        </div>
      </div>
      <!-- 邮箱 -->
      <div class="form-group">
        <label for="email" class="control-label">邮箱 <span class="require">*</span></label>
        <div class="input">
          <input v-model="signupForm.companyemail"
                 id="email"
                 type="email" name="companyemail"
                 class="form-control"
                 :class="{ error : checkField.companyemailinfo }"
                 @blur="checkEmail"
                 @input="!isRegBtnDisable && checkEmail()">
          <div style="color: red">{{ checkField.companyemailinfo }}</div>
        </div>
      </div>
      <!--Checkbox-->
      <div class="form-group">
        <label  class="control-label"/>
        <div class="footer">
          <div style="display: flex;justify-content: center;align-items: center;padding: 40px 0 10px 0;color: #C5C5C5;font-size: 10px;">
            <input v-model="isAgree" type="checkbox" class="checkbox-style">
            我已阅读并接受本站的
            <router-link to="/cn/Terms">
          <span class="text-primary">
            服务条款
          </span>
            </router-link>
            。
          </div>
          <div class="register-footer">
            <button :disabled="isRegBtnDisable" type="submit" class="btn btn-primary" :class="{ notAllowed : isRegBtnDisable }" @click.prevent="handleSignUp">
              注册
            </button>
          </div>
        </div>
      </div>
    </div>
    <common-dialog id="signupDialog" message="regsuccessful"/>
    <common-dialog id="validcodemsgDialog" :message="validcodemsg"/>
  </div>
</template>
<script>

import { countrydata, provincedata } from '@/utils/dictionary'
import CommonDialog from '@/components/cn/CommonDialog'

export default {
  name: 'CNRegister',
  components: { CommonDialog },
  data () {
    return {
      companytype: [],
      isActive: false,
      totaltime: 60,
      countcontent: '获取验证码',
      countrydata: countrydata,
      signupForm: {
        username: '',
        password: '',
        repassword: '',
        companyname: '',
        companylocalname: '',
        companytype: '',
        country: '',
        province: '',
        contactpersonname: '',
        contactpersonsex: '',
        contactpersonenglishname: '',
        companyphone: '',
        companyfax: '',
        cellphone: '',
        companyemail: '',
        website: '',
        producttype: '',
        validcode: '',
        CompanyCountry: '_china'
      },
      // 报错信息
      checkField: {
        usernameinfo: '',
        passwordinfo: '',
        repasswordinfo: '',
        companynameinfo: '',
        companytypeinfo: '',
        countryinfo: '',
        provinceinfo: '',
        contactpersonnameinfo: '',
        contactpersonsexinfo: '',
        companyphoneinfo: '',
        companyemailinfo: '',
        validcodeinfo: ''
      },
      loginForm: {
        UserName: '',
        Password: ''
      },
      producttype: [],
      username: '',
      password: '',
      reEnterPassword: '',
      companyName: '',
      companyNameEn: '',
      website: '',
      notEmptySet: new Set([]),
      isRegBtnDisable: true,
      isAgree: false,
      disableProvince: false,
      validcodemsg: ''
    }
  },
  computed: {
    provincelist () {
      const province = this.signupForm.country
      if (province && province !== '_othercountry') {
        return provincedata[province].split('|')
      }
      return []
    }
  },
  watch: {
    'signupForm.country' (val) {
      if (val === '_othercountry') {
        this.disableProvince = true
      } else {
        this.disableProvince = false
      }
    },
    isAgree (newval, oldval) {
      if (newval !== oldval) {
        this.isRegBtnDisable = this.isDisabled()
      }
    }
  },
  mounted () {
  },
  methods: {
    handleSignUp () {
      this.$store.dispatch('SetUserTemp', {
        Level: '_btcmarket_member',
        UserID: this.signupForm.username
      })
      this.$store.dispatch('SetLoadingStatus', true)
      this.signupForm.companytype = this.companytype.join('|')
      this.signupForm.producttype = this.producttype.join('|')
      const params = { mobile: this.signupForm.companyphone, code: this.signupForm.validcode }
      if (this.isAgree && !this.isRegBtnDisable) {
        this.$store.dispatch('CheckCaptcha', params).then((res) => {
          this.$store.dispatch('Signup', this.signupForm).then(res => {
            this.loginForm.UserName = this.signupForm.username.replace(/\s+/g, '')
            this.loginForm.Password = this.signupForm.password.replace(/\s+/g, '')
            this.$store.dispatch('SetLoadingStatus', false)
            this.$bvModal.show('signupDialog')

            this.autoLogin(this.loginForm)
          }).catch(() => {
            this.$store.dispatch('SetLoadingStatus', false)
          })
        }).catch((err) => {
          this.$store.dispatch('SetLoadingStatus', false)
          if (err.ErrorCode === 6007) {
            this.validcodemsg = 'captchatimeout'
          }
          if (err.ErrorCode === 6008) {
            this.validcodemsg = 'captchaerr'
          }
          this.$bvModal.show('validcodemsgDialog')
        })
      }
    },
    fnCountDown () {
      const phone = this.signupForm.companyphone
      if (phone) {
        this.$store.dispatch('CheckDuplicatePhone', phone).then(() => {
          // 发送验证码
          this.$store.dispatch('GetCaptcha', phone).then(() => {
            this.isActive = true
            const count = window.setInterval(() => {
              this.totaltime--
              this.countcontent = this.totaltime + 's后重新发送'
              if (this.totaltime <= 0) {
                this.totaltime = 60
                this.isActive = false
                this.countcontent = '获取验证码'
                window.clearInterval(count)
              }
            }, 1000)
          })
        }).catch(() => {
          this.checkField.companyphoneinfo = '该手机号已被注册'
        })
      } else {
        this.checkField.companyphoneinfo = '手机号不能为空'
      }
    },
    checkUserName () {
      // 检查用户名
      if (!this.signupForm.username.replace(/\s+/g, '')) {
        this.notEmptySet.delete('username')
        this.checkField.usernameinfo = '请输入用户名'
        this.isRegBtnDisable = this.isDisabled()
      } else if (!/^[a-zA-Z0-9]{6,20}$/.test(this.signupForm.username.replace(/\s+/g, ''))) {
        this.notEmptySet.delete('username')
        this.checkField.usernameinfo = '用户名由6-20个英文字母或数字组成。'
        this.isRegBtnDisable = this.isDisabled()
      } else if (/\s/.test(this.signupForm.username)) {
        this.notEmptySet.delete('username')
        this.checkField.usernameinfo = '用户名不能包含空格。'
        this.isRegBtnDisable = this.isDisabled()
      } else {
        // 用户名查重
        this.$store.dispatch('CheckUserName', this.signupForm.username.replace(/\s+/g, '')).then(() => {
          this.notEmptySet.delete('username')
          this.checkField.usernameinfo = '用户名已被占用'
          this.isRegBtnDisable = this.isDisabled()
        }).catch(() => {
          this.notEmptySet.add('username')
          this.checkField.usernameinfo = ''
          this.isRegBtnDisable = this.isDisabled()
        })
      }
    },
    checkPassword () {
      if (this.signupForm.repassword.replace(/\s+/g, '')) {
        this.chechRepassword()
      }
      if (!this.signupForm.password.replace(/\s+/g, '')) {
        this.notEmptySet.delete('password')
        this.checkField.passwordinfo = '请输入密码'
      } else if (!/^[a-zA-Z0-9]{6,20}$/.test(this.signupForm.password.replace(/\s+/g, ''))) {
        this.notEmptySet.delete('password')
        this.checkField.passwordinfo = '密码由6-20个英文字母或数字组成。'
      } else {
        this.notEmptySet.add('password')
        this.checkField.passwordinfo = ''
      }
      this.isRegBtnDisable = this.isDisabled()
    },
    chechRepassword () {
      if (!this.signupForm.repassword.replace(/\s+/g, '')) {
        this.notEmptySet.delete('repassword')
        this.checkField.repasswordinfo = '请再次输入密码。'
      } else if (this.signupForm.password.replace(/\s+/g, '') !== this.signupForm.repassword.replace(/\s+/g, '')) {
        this.notEmptySet.delete('repassword')
        this.checkField.repasswordinfo = '密码不一致。'
      } else {
        this.notEmptySet.add('repassword')
        this.checkField.repasswordinfo = ''
      }
      this.isRegBtnDisable = this.isDisabled()
    },
    checkEmail () {
      if (!this.signupForm.companyemail.replace(/\s+/g, '')) {
        this.notEmptySet.delete('companyemail')
        this.checkField.companyemailinfo = '请输入邮箱。'
      } else if (!/(\S)+[@]{1}(\S)+[.]{1}(\w)+/.test(this.signupForm.companyemail.replace(/\s+/g, ''))) {
        this.notEmptySet.delete('companyemail')
        this.checkField.companyemailinfo = '邮箱格式不正确。'
      } else {
        // 邮箱查重
        this.$store.dispatch('CheckRegEmail', this.signupForm.companyemail.replace(/\s+/g, '')).then(() => {
          this.notEmptySet.delete('companyemail')
          this.checkField.companyemailinfo = '邮箱已被占用'
          this.isRegBtnDisable = this.isDisabled()
        }).catch(() => {
          this.notEmptySet.add('companyemail')
          this.checkField.companyemailinfo = ''
          this.isRegBtnDisable = this.isDisabled()
        })
      }
    },
    checkCompanyName () { this.isEmptyField('companyname', 'companynameinfo', '请输入公司名称。') },
    checkContact () { this.isEmptyField('contactpersonname', 'contactpersonnameinfo', '请输入联系人。') },
    checkPhone () { this.isEmptyField('companyphone', 'companyphoneinfo', '请输入手机号。') },
    checkCode () { this.isEmptyField('validcode', 'validcodeinfo', '请输入验证码。') },
    checkCompanyType () { this.isEmptyCheck('companytype', 'companytypeinfo', '请选择公司类型。') },
    // checkProductType() { this.isEmptyCheck('producttype', 'producttypeinfo', '请选择公司涉及产品。') },
    checkCountry () { this.isEmptyField('country', 'countryinfo', '请选择国家。') },
    checkProvince () { this.isEmptyField('province', 'provinceinfo', '请选择省份。') },
    checkSex () { this.isEmptyField('contactpersonsex', 'contactpersonsexinfo', '请选择联系人性别。') },

    autoLogin (data) {
      this.$store.dispatch('SetLoadingStatus', true)
      this.$store.dispatch('Login', data).then(() => {
        this.$store.dispatch('GetUserProfile', data.UserName).then(() => {
          this.$store.dispatch('SetLoadingStatus', false)
        }).catch(() => {
          this.$store.dispatch('SetLoadingStatus', false)
        })
      }).catch(() => {
        this.$store.dispatch('SetLoadingStatus', false)
      })
    },
    isEmptyField (attr, infoattr, msg) {
      if (attr === 'country' && this.signupForm.country === '_othercountry') {
        this.notEmptySet.add('province')
        this.checkField.provinceinfo = ''
      }
      if (this.signupForm[attr].replace(/\s+/g, '')) {
        this.notEmptySet.add(attr)
        this.checkField[infoattr] = ''
      } else {
        this.notEmptySet.delete(attr)
        this.checkField[infoattr] = msg
      }
      this.isRegBtnDisable = this.isDisabled()
    },
    isEmptyCheck (attr, attrinfo, msg) {
      if (this[attr].length === 0) {
        this[attrinfo] = msg
        this.notEmptySet.delete(attr)
      } else {
        this[attrinfo] = ''
        this.notEmptySet.add(attr)
      }
      this.isRegBtnDisable = this.isDisabled()
    },
    isDisabled () {
      return !(this.isAgree && this.notEmptySet.size === 12)
    }
  }
}
</script>
<style scoped>
.container-fluid {
  display: flex;
  align-items: center;
  background-color: #f5f5f5;
  flex-direction: column;
  color: #535353;
}

.register {
  font-size: 20px;
  padding: 20px 0;
}

.container-body {
  width: 1200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  border-bottom: 1px solid #F5F5F5;
}

.legend-style {
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 20px 0;
}

.form-group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.control-label {
  width: 350px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 16px;
}

.require {
  margin-left: 5px;
  color: red;
}

input {
  width: 489px;
  height: 35px;
  background-color: #EDEDED;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  padding: 0 15px;
  font-size: 12px;
  margin-left: 20px;
}

.input > div {
  color: #CCCCCC;
  font-size: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
}

.input > div > div:first-child {
  width: 6px;
  height: 6px;
  margin-right: 5px;
}

.checkbox {
  width: 489px;
  font-size: 10px;
  margin-left: 20px;
}

.checkbox > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.checkbox > div > div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0;
}

.checkbox-style {
  width: 14px;
  height: 14px;
  border: 1px solid #707070;
  margin-right: 5px;
}

select {
  width: 160px;
  height: 35px;
  border-radius: 8px;
  border: 1px solid #EDEDED;
  background-color: #EDEDED;
  margin-left: 20px;
}

.form-control {
  background-color: #EDEDED;
}

.footer{
  width: 491px;
  display: flex;
  flex-direction: column;
  justify-self: flex-start;
  align-items: center;
}

.register-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.register-footer > button {
  width: 347px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background-color: #4285F4;
  color: #ffffff;
  font-size: 10px;
  font-weight: bold;
}

.status-point {
  display: inline-block;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #fe5555;
}

.text-primary {
  margin: 0 5px;
}

/*占位符修改*/
input::-webkit-input-placeholder { /* WebKit browsers */
  font-size: 10px;
  color: #CCCCCC;
}

input:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
  font-size: 10px;
  color: #CCCCCC;
}

input::-moz-placeholder { /* Mozilla Firefox 19+ */
  font-size: 10px;
  color: #CCCCCC;
}

input:-ms-input-placeholder { /* Internet Explorer 10+ */
  font-size: 10px;
  color: #CCCCCC;
}
</style>
